import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseApi } from '@services'
import { IUserDetailsResponse } from '@appTypes'

/**
 * @description React Query Key for useGetUserDetails
 * @constant {"get-user-details"}
 * @type {string}
 */
export const GET_USER_DETAILS_QUERY = 'get-user-details'

/**
 * @description API Path for useGetUserDetails
 * @constant {"/user/details"}
 * @type {string}
 */
export const GET_USER_DETAILS_PATH = '/api/v2/user/details'

/**
 * @description  API call to getUserDetails
 * @function useGetUserDetails
 * @returns {Promise<IGetUserResponsePayload, Error>}
 */
export const getUserDetails = async (): Promise<IUserDetailsResponse> =>
  await baseApi.get<IUserDetailsResponse, any>(GET_USER_DETAILS_PATH)

/**
 * @description React Query Hook to get user data
 * @function useGetUser
 * @export
 * @returns {UseQueryResult<IGetUserResponsePayload>}
 */
export const useGetUserDetails = (): UseQueryResult<IUserDetailsResponse> =>
  useQuery<IUserDetailsResponse>({
    queryKey: [GET_USER_DETAILS_QUERY],
    queryFn: async () => await getUserDetails(),
  })
