import { useMutation } from '@tanstack/react-query'
import { queryClient, baseApi } from '@services'

import { IPortfolio } from '@appTypes'

export const deletePortfolio = (id: string): Promise<IPortfolio[]> =>
  baseApi.delete(`/portfolios/${id}`)

export const useDeletePortfolio = () =>
  useMutation(deletePortfolio, {
    onSuccess: () => {
      queryClient.invalidateQueries(['portfolios'])
    },
  })
