import { Maybe, IPortfolio, IAccounts } from '@appTypes'
import { find } from 'lodash'

export enum EPortfolioType {
  SINGLE_ASSET = 'singleAsset',
}

export const isSingleAsset = (portfolio: IPortfolio) =>
  portfolio.type === EPortfolioType.SINGLE_ASSET

export const getPortfolioName = (portfolio: Maybe<IPortfolio>) =>
  portfolio ? portfolio.name || '' : ''

export const findAccountById = (
  portfolio: Maybe<IPortfolio>,
  account: number
): IAccounts | null =>
  portfolio ? find(portfolio.accounts, { id: account }) || null : null
