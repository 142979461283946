import { ChangeEvent } from 'react'
import { IRadioButton } from './common/buttons'

/**
 * @description Type of Table
 * @enum {string}
 * @export
 * @readonly
 */
export enum TableType {
  PORTFOLIO = 'portfolio',
  PRIVATE_ASSETS = 'privateAssets',
  PORTFOLIO_ANALYZE = 'portfolioAnalyze',
  ADMIN_ASSETS = 'adminAssets',
}

export interface columnTable {
  headerName: string
  field: string
  resizable: boolean
  enableRowGroup: boolean
  sort?: 'desc' | 'asc' | ''
  headerClass: string | string[]
  cellClass: string | string[]
  pinned?: 'left' | 'right'
  comparator?: (valueA: number, valueB: number) => number
  valueFormatter?: (params: { value: number }) => string
  minWidth?: number
  maxWidth?: number
  width?: number
}

interface IAutoGroup {
  cellRendererParams: {
    suppressCount: boolean
    innerRendererFramework: () => JSX.Element
  }
  headerName?: string
  minWidth?: number
  headerClass?: string
  cellClass?: string
  resizable?: boolean
}

export interface ITable {
  tableType: TableType | string
  columnDefs: columnTable[]
  rowData: any[]
  strings?: { [key: string]: string }
  autoGroupColumnDef?: IAutoGroup
  sort?: {
    column: string
    direction: string
    update?: (column: string) => void
  }
  treeData?: boolean
  getDataPath?: () => []
  tableHeight?: number | string | undefined
  domLayout?: string
  id?: string | undefined
}

export interface ITableWithRadioButtons extends ITable {
  radioButtons: IRadioButton[]
  radioButtonSelected?: string
  onChangeRadioValue?: (event: ChangeEvent<HTMLInputElement>) => void
}
