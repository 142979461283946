import { useQuery } from '@tanstack/react-query'
import { baseApi } from '@services'

import { IWhiteLabelPartnerStyle } from '@appTypes'

export const getStyles = (): Promise<IWhiteLabelPartnerStyle> =>
  baseApi.get(`/wlp/styles`)

export const useGetStyles = () =>
  useQuery({
    queryKey: ['wlpStyles'],
    queryFn: getStyles,
  })
