import { ICMSStringsResponse, GenericObject, Maybe } from '@appTypes'

export const formatCMSData = (
  data: ICMSStringsResponse[] = []
): Maybe<GenericObject<string>> => {
  if (data === undefined) return
  return data.reduce(
    (acc: GenericObject<string>, curr: ICMSStringsResponse) => {
      acc[curr.type] = curr.body
      return acc
    },
    {}
  )
}
