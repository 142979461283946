import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { SuperNova } from './SuperNova'

import { queryClient } from '@services'

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
  return (
    <Suspense fallback={<></>}>
      <QueryClientProvider client={queryClient}>
        <SuperNova>
          <Router>{children}</Router>
        </SuperNova>
      </QueryClientProvider>
    </Suspense>
  )
}
