import { baseApi } from '@services'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

export const doMakeSAAPortfolio = (assetId: string): Promise<number> =>
  baseApi.post('/portfolios/singleasset', { asset: assetId })

/**
 * @function useDoMakeSAAPortfolio
 * @description React Query to make single asset portfolio
 * @param {number} assetId
 * @returns {UseQueryResult<number>}
 */
export const useDoMakeSAAPortfolio = (
  assetId: string
): UseQueryResult<number> =>
  useQuery<number>({
    queryKey: ['makeSAAPortfolio', assetId],
    queryFn: () => doMakeSAAPortfolio(assetId),
  })
