import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseApi } from '@services'
import { IGetUserResponsePayload } from '@appTypes'

/**
 * @description React Query Key for useGetUser
 * @constant {"get-user"}
 * @type {string}
 */
export const GET_USER_QUERY = 'get-user'

/**
 * @description API Path for useGetUser
 * @constant {"/user/me"}
 * @type {string}
 */
export const GET_USER_PATH = '/user/me'

/**
 * @description React Query Hook to get use data
 * @function useGetUser
 * @export
 * @returns {UseQueryResult<IGetUserResponsePayload>}
 */
export const useGetUser = (): UseQueryResult<IGetUserResponsePayload> =>
  useQuery<IGetUserResponsePayload>({
    queryKey: [GET_USER_QUERY],
    queryFn: async () =>
      await baseApi.get<IGetUserResponsePayload, any>(GET_USER_PATH),
  })
