import { IcnTableColumnDef } from '@icapitalnetwork/react-component-library'

export const sortToColumnDef = (
  columnDef: IcnTableColumnDef[],
  column?: string,
  direction?: string
) =>
  columnDef.map((col) => {
    return {
      ...col,
      ...(col.field === column && {
        headerComponentParams: {
          isSorted: true,
          sortDirection: direction,
        },
      }),
    }
  })

export const nextDirection = (
  column: string,
  current: { sortCol?: string; sortDir?: string }
) => (column !== current.sortCol || current.sortDir === 'desc' ? 'asc' : 'desc')
