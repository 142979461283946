import { Provider } from '@icapitalnetwork/supernova-core'
import { LocalizationProvider } from '@icapitalnetwork/supernova-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import '@icapitalnetwork/supernova-fonts'

interface SuperNovaTypes {
  children: JSX.Element
}

export const SuperNova = ({ children }: SuperNovaTypes) => {
  return (
    <Provider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {children}
      </LocalizationProvider>
    </Provider>
  )
}
