import { EnvironmentUrls } from '@appTypes'

const location = window.location

export const BASE_URL =
  process.env.REACT_APP_API_URL || `${location.protocol}//${location.host}`

export const environmentUrls: EnvironmentUrls = {
  production: 'https://icn-production-files.s3.amazonaws.com/',
  staging: `https://icn-staging-files.s3.amazonaws.com/`,
}
