import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseApi } from '@services'

import { ICMSStringsResponse } from '@appTypes'

export const getCMSStrings = (
  context: string[] = []
): Promise<ICMSStringsResponse[]> =>
  baseApi.get(`/strings/context?names=${context.join(',')}`)

export const useGetCMSStrings = (
  context: string[] = []
): UseQueryResult<ICMSStringsResponse[]> =>
  useQuery({
    queryKey: ['cmsStrings', context],
    queryFn: () => getCMSStrings(context),
  })
