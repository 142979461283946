import { useMutation } from '@tanstack/react-query'
import { queryClient, baseApi } from '@services'
import { IInvitationsPostType } from '@appTypes'

/**
 * @description Axios Put to add invitations
 * @param {IInvitationsPostType[]} invitations
 * @returns {Promise<unknown>}
 */
export const addInvitations = (
  invitations: IInvitationsPostType[]
): Promise<unknown> => baseApi.put(`/invitation/action`, invitations)

/**
 * @description React Query to add invitations
 */
export const useAddInvitations = () =>
  useMutation(addInvitations, {
    onSuccess: () => {
      queryClient.invalidateQueries(['invitations'])
      queryClient.invalidateQueries(['portfolios'])
      queryClient.invalidateQueries(['assets'])
    },
  })
