import React from 'react'
import ReactDOM from 'react-dom'
import { AppProvider } from 'providers/app'
import App from './App'

import './index.scss'
import '@icapitalnetwork/react-component-library/dist/index.css'
import { Honeybadger } from '@honeybadger-io/react'

if (window.honeyBadgerKey) {
  const honeybadgerConfig = {
    apiKey: window.honeyBadgerKey,
    environment: window.environment,
    component: 'factore-react',
    developmentEnvironments: ['dev', 'test'],
  }

  Honeybadger.configure(honeybadgerConfig)
}

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
