import { handleCreate } from '@utils/redirects'

interface assetsFilters {
  search?: string
  featureAssetOnly?: boolean
  privateAssetOnly?: boolean
  includeInactiveAsset?: boolean
}

const getEmptyStateValues = (filters: assetsFilters) => {
  return filters.search || filters.featureAssetOnly
    ? {
        title: 'No assets found.',
        description: 'Try searching another ticker/abbreviation or asset name.',
      }
    : {
        title: 'There are no assets to display.',
        description: 'To get started, create',
        buttonIcon: 'Add',
        buttonText: 'Custom Asset.',
        buttonAction: handleCreate,
      }
}

export { getEmptyStateValues }
