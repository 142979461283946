import { stringify as stringifyQs } from 'query-string'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseApi } from '@services'
import { IInputIdResponseData, IAnalysisSettingsParams } from '@appTypes'

const paramertizePortfoliosForQuery = (params: IAnalysisSettingsParams) =>
  params.portfolios?.map((itm) => {
    if (!itm.accountId) return itm.portfolioId
    return `${itm.portfolioId}-${itm.accountId}`
  }) || []

/**
 * @description Axios Get call to get input id
 * @todo: Add type for params
 * @returns {Promise<IInputIdResponseData>}
 */
export const getInputId = (params: any): Promise<IInputIdResponseData> => {
  const queryString = stringifyQs({
    ...params,
    portfolios: paramertizePortfoliosForQuery(params).join(','),
  })
  return baseApi.get(`/portfolioAnalysisInput?${queryString}`)
}

/**
 * @todo: Add type for params
 * @description React Query to get input id
 * @returns {UseQueryResult<IInputIdResponseData>}
 */
export const useGetInputId = (
  params: any = {},
  shouldLoad = true
): UseQueryResult<IInputIdResponseData> =>
  useQuery({
    queryKey: ['inputId', params],
    queryFn: () => getInputId(params),
    enabled: params.portfolios.length > 0 && !!params.riskModelId && shouldLoad,
  })
