/* eslint-disable camelcase */
import HighchartsReact from 'highcharts-react-official'
import { columnTable, Maybe, GenericObject } from '@appTypes'

/**
 * @typedef AnalysisType
 * @type {string}
 *
 * @description Type of analysis
 */
export type AnalysisType =
  | 'allocationDrilldown'
  | 'assetClassCorrMatrix'
  | 'pastMarketEvents'
  | 'factorExposures'
  | 'c2rDrilldown'
  | 'growth1kFullHistory'
  | 'rollingBetas'

/**
 * @type {object}
 * @description Parameters for analysis charts API Calls and React Query
 */
export interface IAnalysisChartsParams {
  /**
   * @description Input ID
   * @type {string|number}
   * @memberof IAnalysisChartsParams
   */
  inputId?: string | number
  /**
   * @description Input IDs
   * @type {(string|number)[]}
   * @memberof IAnalysisChartsParams
   */
  inputIds?: (string | number)[]
  /**
   * @description Analysis type
   * @type {AnalysisType}
   * @memberof IAnalysisChartsParams
   */
  analysisType?: AnalysisType
}

/**
 * @description
 * This hook is used to get the account id for a given a portfolioId.
 */
export interface IAnalysisSettingsPortfolioParams {
  portfolioId: string
  accountId: string | undefined
}

/**
 * @type {object}
 * @description Parameters for analysis settings API Calls and React Query
 */
export interface IAnalysisSettingsParams {
  /**
   * @description Portfolio ID
   * @type {Array<string|number>}
   * @memberof IAnalysisSettingsParams
   */
  portfolios?: IAnalysisSettingsPortfolioParams[]
  /**
   * @todo: Insert description for riskMode
   */
  riskModel?: string | number
}

/**
 * @type {object}
 * @description Response Data for analysis settings API Calls and React Query
 */
export interface IInputId {
  comparison: boolean
  createdAt: string
  endDate: string
  forceFrequency: number | null
  id: number
  investmentAccount: string | null
  keepEndDateCurrent: boolean
  portfolio: number
  riskModel: number
  scenarioPreferences: string | null
  startDate: string
  state: string
  updatedAt: string
  useExtendedLifetime: boolean
  valid: boolean
  windowSize: number
  windowType: number
  isWindowAnalysis: boolean
}

export interface ISeparateInput {
  input: IInputId
  eligibility: IInputEligibility
}

export interface IInputEligibilityOptions {
  shouldShow: boolean
  message: string
}

export interface IInputEligibilityFactorOptions
  extends IInputEligibilityOptions {
  shouldShowFactorBased: boolean
}

export interface IInputEligibility {
  assetClassCorrMatrix: IInputEligibilityOptions
  factorExposures: IInputEligibilityOptions
  c2rDrilldown: IInputEligibilityOptions
  allocationDrilldown: IInputEligibilityOptions
  pastMarketEvents: IInputEligibilityFactorOptions
  growth1kFullHistory: IInputEligibilityFactorOptions
}

export interface IInputIdResponseData {
  input?: IInputId
  inputs?: ISeparateInput[]
  eligibility: IInputEligibility
  factors: any
  riskModel: any
}

/**
 * @description Describes the attributes inside the date filter option
 *
 * @export
 * @interface IDateRangeOption
 * @typedef {IDateRangeOption}
 */
export interface IDateRangeOption {
  displayText: string
  modelDisplayText: string
  minDate: string
  maxDate: string
  modelMinDate: string
  modelMaxDate: string
  dateRangeInMonths: number
  isWindowAnalysis: boolean
}

export type RiskModel = {
  id: number
  name: string
  lifetimeModelMinDate: string
  lifetimeModelMaxDate: string
  description?: string
}

export interface IAnalysisSettingsResponseData {
  riskModelOptions: RiskModel[]
  currentRiskModel: RiskModel
  displayName: string
  isSingleAsset: boolean
  isBaseAnAsset: boolean
  hasSingleAsset: boolean
  isComparingOnlyAssets: boolean
  portfolioNames: string[]
  currentDateRangeOptions: IDateRangeOption[]
}
export interface IFilters {
  riskModelId: number
  windowSize: number
  displayText?: string
  isWindowAnalysis: boolean
  startDate: string
  endDate: string
}

export interface IAnalysisResponseData {
  createdAt: string
  updatedAt: string
  id: number
  analysisType: string
  key: string
  input: number
  portfolioLevelAnalysisIsUnreliable?: boolean
  reliabilityMessage?: string
}

export interface ICorrelationResponseData extends IAnalysisResponseData {
  output: {
    date: string
    assetClassCorrelationMatrix: number[][]
    assetClassColumnAndRowLabels: string[]
    tickerColumnLabels: string[] | number[]
    tickerColumnDisplayNames: string[] | number[]
    assetClassInfo: any
  }
}

export interface IFactorExposureData {
  portfolio_betas: {
    factors: GenericObject<number>
    latent_factors: GenericObject<number>
    max_latent_factor: GenericObject<number>
  }
  by_holding_betas: {
    factors: GenericObject<GenericObject<number>>
    latent_factors: GenericObject<GenericObject<number>>
    max_latent_factor: GenericObject<GenericObject<number>>
  }
  intercept: number
  has_latent_factors: boolean
}

export interface IFactorExposureResponseItemData extends IAnalysisResponseData {
  output: IFactorExposureData
  defaultRollingWindowSize?: number
}

export interface IFactorExposureResponseMultiData {
  portfolios: IFactorExposureResponseData[]
}

export type IFactorExposureResponseData =
  | IFactorExposureResponseItemData
  | IFactorExposureResponseMultiData

export interface IAllocationDrilldownResponseData
  extends IAnalysisResponseData {
  holdings?: { detail?: Record<string, string | number>[] }
}

export type IFactorTickerSeries = Maybe<{
  name: string
  color: string
  data: number[]
}>

export interface IStatusMessages {
  unupdatableErrorMessage?: string
  staleAssetErrorMessage?: string
  inactiveAndStaleAssetErrorMessage?: string
  inactiveAssetButNotStaleErrorMessage?: string
  ineligibleAssetErrorMessage?: string
  unreliableErrorMessage?: string
  proxyAssetMessage?: string
}

export interface ICompositionsChartsResponsePortfolio {
  id?: number
  name: string
  assetClasses: ICompositionsChartsAssetClasses
  holdings: ICompositionsChartsHoldings
  accounts?: ICompositionsChartsAccounts[]
}

export interface ICompositionsChartsResponseSimple
  extends ICompositionsChartsResponsePortfolio {
  statusMessages?: IStatusMessages
}

export interface ICompositionsChartsResponseMultiple {
  statusMessages?: IStatusMessages
  portfolios: ICompositionsChartsResponsePortfolio[]
}

export type ICompositionsChartsResponse =
  | ICompositionsChartsResponseSimple
  | ICompositionsChartsResponseMultiple

export const compositionsChartsResponseDefault = {
  id: 0,
  name: '',
  assetClasses: { detail: [] },
  holdings: { detail: [] },
  statusMessages: {
    unupdatableErrorMessage: '',
    staleAssetErrorMessage: '',
    inactiveAndStaleAssetErrorMessage: '',
    inactiveAssetButNotStaleErrorMessage: '',
    ineligibleAssetErrorMessage: '',
    unreliableErrorMessage: '',
    proxyAssetMessage: '',
  },
}

export interface ICompositionsChartsAssetClasses {
  totalValue: number
  detail: (ICompositionsChartsAsset & {
    drilldown?: ICompositionsChartsAsset[]
  })[]
}

export interface ICompositionsChartsHoldings {
  totalValue: number
  detail: ICompositionsChartsAsset[]
}

export interface ICompositionsChartsAccountAsset {
  ticker: string
  name: string
  displayName: string
  percentage: number
  amount: number
}

export interface ICompositionsChartsAccounts {
  id?: number
  name: string
  weight: number
  holdings: Partial<ICompositionsChartsAccountAsset>[]
}

export interface ICompositionsChartsFormatted {
  series: {
    name: string
    amount: number
    data: (ICompositionsChartsAsset & { y: number; drilldown?: string })[]
  }[]
  drilldown: (ICompositionsChartsAsset & {
    id: string
    data: (ICompositionsChartsAsset & { y: number })[]
  })[]
}

export type ChartRefHandler<T = void> = (
  chartRef: HighchartsReact.RefObject,
  chartName?: keyof T
) => void

export interface ICompositionsChartsAsset {
  ticker: string
  name: string
  displayName: string
  amount: number
  percentage: number
  color: string
}

export interface IScenarioResponseDataDetail {
  [key: string]: {
    gain_or_loss_percent: number
    start_date: string
    end_date: string
    description?: string
  }
}
export interface IScenarioResponseDataItem extends IAnalysisResponseData {
  lifetime_factor_based: IScenarioResponseDataDetail
  real_return_based: IScenarioResponseDataDetail
  market_value: number
}

export interface IScenarioResponseDataMultiple {
  portfolios: IScenarioResponseDataItem[]
}

export type IScenarioResponseData =
  | IScenarioResponseDataItem
  | IScenarioResponseDataMultiple

export interface IExposureData {
  columns: columnTable[]
  rows: { factor: string; portfolio: number }[]
}

export interface IGrowth1kReturnOutput {
  dates: string[]
  values: number[]
  returns: number[]
  cashReturns: number[]
}

export interface IGrowth1KResponseDataItem extends IAnalysisResponseData {
  output: {
    factorBased: IGrowth1kReturnOutput
    real: IGrowth1kReturnOutput
    allDates: string[]
    crossValidatedRSquared?: number
    inSampleRSquared?: number
    trackingError?: number
  }
}

export interface IGrowth1KResponseDataMultiple {
  portfolios: IGrowth1KResponseDataItem[]
}

export type IGrowth1KResponseData =
  | IGrowth1KResponseDataItem
  | IGrowth1KResponseDataMultiple

export interface IGrowth1kCalculatorInput {
  factorDates: string[]
  factorReturns: number[]
  factorCashReturns: number[]
  factorTableReturns: number[]
  realDates: string[]
  realReturns: number[]
  realTableReturns: number[]
  realCashReturns: number[]
  allDates: string[]
  crossValidatedRSquared?: number[]
}

export interface ISiUnit {
  value: number
  symbol: string
  digits: number
}
