import { PolicyError } from '@appTypes'
import { protectedRoutesPath } from 'routes/protected'
import { getIcnLoginUrl } from '@utils'
import { GET_USER_PATH } from '@queries'

export interface IPolicyError {
  policyError?: PolicyError
}

const excludedPaths = [protectedRoutesPath.landingPage]
const excludedErrors = [
  PolicyError.SUBSCRIPTION_EXPIRED,
  PolicyError.ACCESS_EXPIRED,
  PolicyError.FREE_TRIAL_EXCLUDED,
]

const policies: Partial<
  Record<
    PolicyError,
    ({
      path,
      icnLoginPath,
    }: Partial<{ path: string; icnLoginPath: string }>) => () => void
  >
> = {
  [PolicyError.EULA]: () => () => {
    window.location.replace('/eula')
  },
  [PolicyError.ADMIN]:
    ({ icnLoginPath }) =>
    () => {
      window.location.href = icnLoginPath || ''
    },
  [PolicyError.SIGNIN]:
    ({ path, icnLoginPath }) =>
    () => {
      if (path !== GET_USER_PATH) {
        window.location.href = icnLoginPath || ''
      }
    },
  [PolicyError.SUBSCRIPTION_EXPIRED]: () => () => {
    window.location.replace('/subscription')
  },
  [PolicyError.ACCESS_EXPIRED]: () => () => {
    window.location.replace('/accessExpired')
  },
  [PolicyError.SITE_MAINTENANCE]: () => () => {
    window.location.replace('/accessExpired')
  },
  [PolicyError.FREE_TRIAL_EXCLUDED]: () => () => {
    window.location.replace('/subscription')
  },
}

const getPolicyErrorAction = (
  errorType: PolicyError,
  icnLoginPath: string,
  path: string
): (() => void) => {
  const defaultAction = () => (window.location.href = icnLoginPath)
  const action = policies[errorType]
  return action?.({ icnLoginPath, path }) || defaultAction
}

export const handlePolicyError = (
  errorResponse: IPolicyError,
  path: string
) => {
  const errorType = errorResponse?.policyError
  const isPolicyError = errorType !== undefined
  const currentPath = window?.location?.pathname

  const isExcluded =
    excludedPaths.includes(currentPath) &&
    excludedErrors.includes(errorType as PolicyError)

  if (!isPolicyError || isExcluded) return

  const icnLoginPath = getIcnLoginUrl()

  const policyAction = getPolicyErrorAction(errorType, icnLoginPath, path)
  policyAction()
}
