import { Navigate } from 'react-router-dom'
import { lazyImport } from '@utils/lazyImport'

const { AssetsList } = lazyImport(
  () => import('@pages/adminPages/'),
  'AssetsList'
)

export const adminRoutesPath = {
  assetsList: '/admin/assets-list',
  assetsDetail: '/admin-asset-details',
}

export const adminRoutes = [
  {
    path: adminRoutesPath.assetsList,
    element: <AssetsList />,
    isUnderConstruction: false,
  },
  {
    path: '*',
    element: <Navigate to={adminRoutesPath.assetsList} />,
    isUnderConstruction: false,
  },
]
