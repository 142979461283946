import Highcharts from 'highcharts'
import hcMore from 'highcharts/highcharts-more'
import drilldown from 'highcharts/modules/drilldown'
import Exporting from 'highcharts/modules/exporting'
import { useCallback, useEffect, useMemo } from 'react'
import { AppRoutes } from 'routes'
import { adminRoutes } from 'routes/admin'
import { publicRoutes, publicRoutesHash } from 'routes/public'
import { filterUnderConstructionRoutes } from 'routes/protected'
import { useLocation } from 'react-router-dom'
import { useGetSsoAuthUrl, useGetUser } from '@queries'

Exporting(Highcharts)
hcMore(Highcharts)
drilldown(Highcharts)

function App() {
  const { pathname } = useLocation()
  const {
    data: { me = null } = {},
    isLoading,
    isError,
    isFetched,
  } = useGetUser()

  const isPublicRoute = useMemo(
    () => !!publicRoutesHash[pathname],
    [pathname, publicRoutesHash]
  )

  const { data: { authUrl = null } = {} } = useGetSsoAuthUrl({
    enabled: !isPublicRoute && isFetched && !me,
  })

  const renderPublicRoutes = useCallback(
    () => <AppRoutes routes={publicRoutes} />,
    [publicRoutes]
  )

  useEffect(() => {
    if (isError && !isPublicRoute && authUrl) {
      window.location.href = authUrl
    }
  }, [isError, isPublicRoute, authUrl])

  const renderRoutes = useCallback(() => {
    if (isPublicRoute || !me) return renderPublicRoutes()

    const routes = filterUnderConstructionRoutes(me.underConstructionAccess)
    const adminProtectedRoutes = me.admin ? adminRoutes : []

    return <AppRoutes routes={[...routes, ...adminProtectedRoutes]} />
  }, [me, isFetched, isPublicRoute])

  return isLoading ? null : renderRoutes()
}

export default App
