import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseApi } from '@services'
import { IPortfolio } from '@appTypes'

export const getPortfolio = (portfolioId: string): Promise<IPortfolio> =>
  baseApi.get(`/portfolios/${portfolioId}`)

export const useGetPortfolio = (portfolioId = ''): UseQueryResult<IPortfolio> =>
  useQuery<IPortfolio>({
    queryKey: ['portfolio', portfolioId],
    queryFn: () => getPortfolio(portfolioId),
    enabled: !!portfolioId,
  })
