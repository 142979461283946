import { Navigate } from 'react-router-dom'
import { lazyImport } from '@utils/lazyImport'

const { LandingPage } = lazyImport(() => import('@pages'), 'LandingPage')

const { EnhancementsPage } = lazyImport(
  () => import('@pages'),
  'EnhancementsPage'
)

const { PortfolioPage } = lazyImport(() => import('@pages'), 'PortfolioPage')

const { AnalyzePage } = lazyImport(() => import('@pages'), 'AnalyzePage')

const { AssetsPage } = lazyImport(() => import('@pages'), 'AssetsPage')

const { AnalysisResult } = lazyImport(() => import('@pages'), 'AnalysisResult')

export const protectedRoutesPath = {
  landingPage: '/',
  constructPortfolio: '/manage-portfolio',
  createPortfolio: '/create-portfolio',
  constructAssets: '/manage-portfolio/assets',
  createAsset: '/create-private-asset',
  privateAsset: '/private-assets',
  assets: '/assets',
  analysis: '/factor-analysis',
  analysisResult: '/factor-analysis/result/:portfolio',
  analysisResultAccount: '/factor-analysis/result/:portfolio/:account',
  comparisonResult: '/comparison/results/:portfolio/*',
  compare: '/comparison',
  profile: '/profile',
  enhancements: '/enhancements',
}

export const protectedRoutes = () => [
  {
    path: protectedRoutesPath.landingPage,
    element: <LandingPage />,
    isUnderConstruction: false,
  },
  {
    path: `${protectedRoutesPath.constructPortfolio}/*`,
    element: <PortfolioPage />,
    isUnderConstruction: false,
  },
  {
    path: protectedRoutesPath.constructAssets,
    element: <AssetsPage />,
    isUnderConstruction: false,
  },
  {
    path: protectedRoutesPath.enhancements,
    element: <EnhancementsPage />,
    isUnderConstruction: false,
  },
  {
    path: protectedRoutesPath.analysis,
    element: <AnalyzePage />,
    isUnderConstruction: false,
  },
  {
    path: protectedRoutesPath.analysisResult,
    element: <AnalysisResult />,
    isUnderConstruction: false,
  },
  {
    path: protectedRoutesPath.analysisResultAccount,
    element: <AnalysisResult />,
    isUnderConstruction: false,
  },
  {
    path: protectedRoutesPath.comparisonResult,
    element: <AnalysisResult />,
    isUnderConstruction: false,
  },
  {
    path: '/*',
    element: <Navigate to={protectedRoutesPath.landingPage} />,
    isUnderConstruction: false,
  },
]

export const filterUnderConstructionRoutes = (
  userHasUnderConstructionAccess: boolean
) =>
  userHasUnderConstructionAccess
    ? protectedRoutes()
    : protectedRoutes().filter((route) => !route.isUnderConstruction)
