export enum PolicyError {
  ADMIN = 1,
  SUBSCRIPTION_EXPIRED = 2,
  EULA = 3,
  SIGNIN = 4,
  ACCESS_EXPIRED = 5,
  SITE_MAINTENANCE = 6,
  FREE_TRIAL_EXCLUDED = 7,
  ALLOCATOR_LEVEL_EXCLUDED = 8,
  NO_API_EXCLUDED = 9,
}
