import { useQuery } from '@tanstack/react-query'
import { baseApi } from '@services'

import { IAssetResponseData, IAssetsHome } from '@appTypes'

export const getAssets = (params: any): Promise<IAssetResponseData> => {
  const queryString = new URLSearchParams(params).toString()
  return baseApi.get(`/assets/searchv3?${queryString}`)
}

export const getAssetsHome = (): Promise<IAssetsHome> => {
  return baseApi.get(`/assets/home`)
}

export const useAssets = (params: any) =>
  useQuery({
    queryKey: ['assets', params],
    queryFn: () => getAssets(params),
  })

export const useAssetsHome = () =>
  useQuery({
    queryKey: ['assetsHome'],
    queryFn: () => getAssetsHome(),
  })
