import Axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { BASE_URL } from '@constants'
import { getCsrfToken, handlePolicyError, IPolicyError } from '@utils'

function authRequestInterceptor(config: AxiosRequestConfig) {
  const csrfToken = getCsrfToken()
  const reqHeaders = config.headers as any

  if (csrfToken) reqHeaders.common['X-XSRF-TOKEN'] = csrfToken
  reqHeaders.Accept = 'application/json'
  config.withCredentials = true

  return config
}

export const baseApi = Axios.create({
  baseURL: BASE_URL,
})

baseApi.interceptors.request.use(authRequestInterceptor)
baseApi.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data
  },
  ({ response, config }) => {
    handlePolicyError(response?.data as IPolicyError, config.url)
    return Promise.reject(response)
  }
)
