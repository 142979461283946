import { stringify as stringifyQs } from 'query-string'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseApi } from '@services'
import {
  IAnalysisSettingsParams,
  IAnalysisSettingsResponseData,
} from '@appTypes'

const parameterizePortfoliosForQuery = (params: IAnalysisSettingsParams) =>
  params.portfolios?.map((itm) => {
    if (!itm.accountId) return itm.portfolioId
    return `${itm.portfolioId}-${itm.accountId}`
  }) || []

/**
 * @function getSettings
 * @description Get analysis settings
 * @param {IAnalysisSettingsParams} params
 * @returns Promise<IAnalysisSettingsResponseData>
 */
export const getSettings = (
  params: IAnalysisSettingsParams
): Promise<IAnalysisSettingsResponseData> => {
  const queryString = stringifyQs({
    ...params,
    portfolios: parameterizePortfoliosForQuery(params).join(','),
  })
  return baseApi.get(`/portfolioAnalysisSettings?${queryString}`)
}

/**
 * @function useGetSettings
 * @description React Query to get analysis settings
 * @param {IAnalysisSettingsParams} params
 * @returns {UseQueryResult<IAnalysisSettingsResponseData>}
 */
export const useGetSettings = (
  params: IAnalysisSettingsParams
): UseQueryResult<IAnalysisSettingsResponseData> =>
  useQuery<IAnalysisSettingsResponseData>({
    queryKey: ['settings', params],
    queryFn: () => getSettings(params),
    enabled: !!params.portfolios?.length,
  })
