import { IAsset } from '@appTypes'

export const formatAssetToCard = (
  assets: IAsset[] = [],
  onClick: (id: number) => void,
  color: string
) => {
  return assets.map((asset) => ({
    title: asset.assetClass,
    subtitle: asset.assetType,
    description: asset.displayName,
    color: color,
    onClick: () => onClick(asset.id),
  }))
}
