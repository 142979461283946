import dayjs from 'dayjs'
import { Maybe } from '@appTypes'

export enum MONTH_YEAR {
  DASH = 'MMM-YYYY',
  SLASH = 'MM/YYYY',
}

export const monthYearDashed = (
  date: Maybe<string | Date | number> = undefined
) => dayjs(date).format(MONTH_YEAR.DASH)
export const monthYearSlashed = (
  date: Maybe<string | Date | number> = undefined
) => dayjs(date).format(MONTH_YEAR.SLASH)

export enum DATE {
  DOT = 'MM.DD.YYYY',
  DASH = 'MM-DD-YYYY',
  SLASH = 'MM/DD/YYYY',
  EUROPEAN_DOT = 'DD.MMM.YYYY',
  EUROPEAN_DASH = 'DD-MMM-YYYY',
  EUROPEAN_SLASH = 'DD/MMM/YYYY',
}

export const dateDotted = (date: Maybe<string | Date | number> = undefined) =>
  dayjs(date).format(DATE.DOT)

export const europeanDateDashed = (
  date: Maybe<string | Date | number> = new Date()
) => dayjs(date).format(DATE.EUROPEAN_DASH)

export const getStartOfNextMonth = (dateAsString: string) => {
  const date = dayjs(dateAsString)
  if (!date.isValid()) {
    return ''
  }

  const startOfNextMonth = date.month(date.month() + 1).date(1)
  return startOfNextMonth.format('YYYY-MM-DD')
}
