/*
 * This is a clone of this is meant to be replace
 *  DarkBlueUI/src/app/utils/icnUtil.ts
 * Try to keep the files in sync until angular is removed
 */

import { isProduction } from './environmentUtil'
import { environmentUrls } from '@constants'

export const getIcnUrl = () => window.icnUrl

export const getIcnLoginUrl = () =>
  `${window.icnUrl}/login?referral=portfolio_intelligence`

export const getIcnLogoutUrl = () =>
  `${window.icnUrl}/api/v1/logout?referral=portfolio_intelligence`

export const getWlpLogoUrl = (wlpUrl: string): string => {
  const WlpUrlProduction = `${environmentUrls.production}${wlpUrl}`
  const WlpUrlStaging = `${environmentUrls.staging}${wlpUrl}`
  return isProduction() ? WlpUrlProduction : WlpUrlStaging
}
