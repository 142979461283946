import { GenericObject } from '@appTypes'

export const portfolioStringsFallbackValues: GenericObject<string> = {
  portfolio_inlist_has_unidentifiable_asset:
    'This portfolio contains one or more assets that are not identifiable in our system. \nThe asset returns will remain stale. \nPlease update these assets or recreate your portfolio.',
  portfolio_inlist_has_invalid_holding: 'Invalid Holding.',
}

export const assetStringsFallbackValues: GenericObject<string> = {
  landingpage_cardtitle_1: 'Construct',
  landingpage_carddescription_1: 'Construct models and client portfolios',
  landingpage_cardtitle_2: 'Analyze',
  landingpage_carddescription_2: 'Analyze risks and returns',
  landingpage_cardtitle_3: 'Compare',
  landingpage_carddescription_3: 'Compare portfolio proposals',
  asset_not_updatable: 'Asset not updatable',
  asset_status_analysis_ineligible: 'Analysis status ineligible',
  asset_status_stale: 'Status stale',
  asset_status_inactivenotstale: 'Status inactive (not stale)',
  asset_status_inactivestale: 'Status inactive (stale)',
}
