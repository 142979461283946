import Highcharts from 'highcharts'

export enum EHighchartsLegendSymbol {
  /**
   * Line
   * @description Appears as a circle with a line through it
   */
  Line = Highcharts.seriesTypes.line.prototype.drawLegendSymbol,
  /**
   * Column
   * @description Appears as a circle
   */
  Column = Highcharts.seriesTypes.column.prototype.drawLegendSymbol,
}

export const overrideHighchartsLineChartsLegendSymbol = (
  symbol: EHighchartsLegendSymbol
): void => {
  Highcharts.seriesTypes.line.prototype.drawLegendSymbol = symbol
}

export const resetHighchartsLineChartsLegendSymbol = (): void => {
  Highcharts.seriesTypes.line.prototype.drawLegendSymbol =
    EHighchartsLegendSymbol.Line
}
