import { baseApi } from '@services'

interface IPortfolioAnalysisReportPayload {
  reportOptions: {
    document: any
    factorELogo: any
    userLogo: any
    header: any
  }
}

export const makePortfolioAnalysisReport = async (
  payload: IPortfolioAnalysisReportPayload
): Promise<Blob> =>
  await baseApi.post<Blob, any>('portfolioAnalysis/report', payload, {
    responseType: 'blob',
  })
