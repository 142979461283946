import { ILogoStyle } from '@appTypes'
import { INavbarColorStyles } from '@organisms'

export const defaultLogoStyle: ILogoStyle = {
  height: '26px',
  width: '109px',
}

export const defaultColorStyle: INavbarColorStyles = {
  dropdownColorStyle: {
    dropdownBackgroundColor: '#0077AA',
    dropdownItemColor: {
      color: 'white',
      hover: '#2CA8ED',
      hoverText: 'white',
    },
  },
  navbarBackgroundColor: '#0077AA',
  navbarItemColor: {
    color: 'white',
    hover: '#2CA8ED',
    hoverText: 'white',
  },
}
