import { stringify as stringifyQs } from 'query-string'
import { useQuery, UseQueryResult, useQueries } from '@tanstack/react-query'
import { baseApi } from '@services'
import { IAnalysisChartsParams } from '@appTypes'

/**
 * @function getAnalysis
 * @template T
 * @description Axios Get call to get analysis
 * @param {IAnalysisChartsParams} params
 * @returns {Promise<T, any>}
 */
export const getAnalysis = async <T>(
  params: IAnalysisChartsParams
): Promise<T> => {
  const queryString = stringifyQs(params as Record<string, string>)
  return await baseApi.get<T, any>(`/portfolioAnalysis2?${queryString}`, {
    validateStatus: (status) => status >= 200 && status < 300 && status !== 202,
  })
}

const retryTimer = (attempt: number) => Math.min(600 * 1.1 ** attempt, 5000)

/**
 * @function useGetAnalysis
 * @template T
 * @description React Query to get analysis
 * @param {IAnalysisChartsParams} params
 * @returns {UseQueryResult<T>}
 */
export const useGetAnalysis = <T>(
  params: IAnalysisChartsParams,
  shouldLoad = true,
  hasCache = false
): UseQueryResult<T> => {
  return useQuery<T>({
    queryKey: ['analysis', params],
    queryFn: async () => await getAnalysis(params),
    enabled: (!!params.inputId || !!params.inputIds?.length) && shouldLoad,
    retry: (failureCount, error: any) => {
      return failureCount <= 30 && error?.status === 202
    },
    retryDelay: retryTimer,
    cacheTime: hasCache ? 35000 : 0,
  })
}
