import { lazyImport } from '@utils/lazyImport'
import { invert } from 'lodash'
import { getIcnLogoutUrl } from '@utils'

const { SigninPage } = lazyImport(() => import('@pages'), 'SigninPage')

export const publicRoutesPath = {
  signin: '/signin',
  logout: getIcnLogoutUrl(),
}

/**
 * A Hash of routes by paths with keys starting with /.
 */
export const publicRoutesHash = invert(publicRoutesPath)

export const publicRoutes = [
  {
    path: `${publicRoutesPath.signin}/*`,
    element: <SigninPage />,
  },
  {
    path: '*',
    element: <></>,
  },
]
