import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { baseApi } from '@services'
import { ISsoAuthUrlResponse } from '@appTypes/response/sso'

export const GET_SSO_AUTH_QUERY = 'get-sso-auth'

export const useGetSsoAuthUrl = (
  params: UseQueryOptions<ISsoAuthUrlResponse> = {}
) => {
  return useQuery<ISsoAuthUrlResponse>({
    queryKey: [GET_SSO_AUTH_QUERY],
    queryFn: async () =>
      await baseApi.get<ISsoAuthUrlResponse, any>('api/v1/sso/auth'),
    ...params,
  })
}
