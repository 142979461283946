import { NodeEnvironment } from 'shared/types'

export const s3Domains = {
  dev: 'icn-staging-files.s3.amazonaws.com',
  development: 'icn-staging-files.s3.amazonaws.com',
  test: 'icn-staging-files.s3.amazonaws.com',
  staging: 'icn-staging-files.s3.amazonaws.com',
  production: 'icn-production-files.s3.amazonaws.com',
}

export const getS3Url = (nodeEnv: NodeEnvironment) =>
  s3Domains[nodeEnv] || s3Domains.development
