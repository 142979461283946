import Highcharts from 'highcharts'
import { sortBy } from 'lodash'
import { ISiUnit } from '@appTypes'

const getSiUnit = (num: number): ISiUnit => {
  const si = [
    { value: 1, symbol: '', digits: 0 },
    { value: 1e3, symbol: 'k', digits: 1 },
    { value: 1e6, symbol: 'mm', digits: 2 },
    { value: 1e9, symbol: 'bn', digits: 2 },
    { value: 1e12, symbol: 'tn', digits: 2 },
  ]

  let siUnit = si[0]
  for (let i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      siUnit = si[i]
      break
    }
  }
  return siUnit
}

const convertToSiUnit = (num: number, siUnit: ISiUnit, prefix = '') => {
  const re = /\.0+$|(\.[0-9]*[1-9])0+$/
  return `${prefix}${(num / siUnit.value)
    .toFixed(siUnit.digits)
    .replace(re, '$1')} ${siUnit.symbol}`
}

export const getHighestMoneyFormat = (
  series: Highcharts.PointOptionsObject[]
) => {
  const sortedSeries = sortBy(series, (serie) => Math.abs(serie.y || 0))
  return getSiUnit(sortedSeries[0]?.y || 0)
}

export function abbMoneyFormat(num: number, suffix = '') {
  const siUnit = getSiUnit(num)
  return convertToSiUnit(num, siUnit, suffix)
}

/**
 * @function applyMoneyFormat
 * @description Sets manually a specific money format for a number
 * @param siUnit
 * @param num
 * @param suffix
 * @returns {string}
 */
export const applyMoneyFormat = (siUnit: ISiUnit, num: number, suffix = '') => {
  return convertToSiUnit(num, siUnit, suffix)
}
