import { useQuery } from '@tanstack/react-query'
import { baseApi } from '@services'
import { IPortfoliosResponseData } from '@appTypes'

export const getAllPortfolios = (
  params: any
): Promise<IPortfoliosResponseData> => {
  const queryString = new URLSearchParams(params).toString()
  return baseApi.get(`/portfolios/listAllv2?${queryString}`)
}

export const useGetAllPortfolios = (params: any = {}) => {
  return useQuery({
    queryKey: ['portfolios', params],
    queryFn: () => getAllPortfolios(params),
  })
}
