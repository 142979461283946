import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { baseApi } from '@services'
import { InvitationAssetCollection } from '@appTypes'

/**
 * @function
 * @description Axios Get call to get invitations
 *
 * @returns {InvitationAssetResponse}
 */
export const getInvitations = (): Promise<InvitationAssetCollection> =>
  baseApi.get(`/invitation/portfolioasset`)

/**
 * @function
 * @description React Query to get invitations
 *
 * @returns {UseQueryResult<InvitationAssetCollection>}
 */
export const useGetInvitations =
  (): UseQueryResult<InvitationAssetCollection> =>
    useQuery<InvitationAssetCollection>({
      queryKey: ['invitations'],
      queryFn: () => getInvitations(),
    })
